<template>
  <section-quit-header class="h-0" />

  <div class="relative flex w-full min-h-[31.25rem] md:min-h-[40.5rem] font-roboto">
    <picture>
      <source :srcset="home_meta.image1.desktop.url" media="(orientation: landscape)" />
      <img class="absolute inset-0 w-full h-full object-cover object-[50%_75%]" :src="home_meta.image1.mobile.url" />
    </picture>

    <div class="relative flex-none flex flex-col w-full">
      <div class="flex-none my-auto pt-20 pb-12 lg:pt-26 lg:pb-24">
        <div class="brace font-degular font-bold">
          <p class="max-w-[280px] md:max-w-[512px] mb-4.5 text-lg/[1.15] md:text-2xl/[1.15]"
             v-html="t('hero.title')"></p>

          <p class="max-w-[268px] md:max-w-[580px] uppercase text-green text-[2.5rem]/[1] md:text-[4rem]/[1]">
            <strong class="font-extrabold" v-html="t('hero.body')"></strong>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="brace relative text-center">
    <arrow-down class="inline-block text-green !w-[24px] !h-[110px] -mt-[55px]" />
  </div>

  <div class="brace mt-11 mb-8 lg:mt-8 lg:mb-14">
    <h2 class="max-w-[28em] mb-6 lg:md-5 mx-auto text-2xl/[1.3] lg:text-3.5xl/[1.3] font-bold text-center uppercase">
      {{ t('main.title') }}
    </h2>

    <div class="max-w-[48em] mx-auto px-4 text-[1rem] text-center">
      {{ t('main.body') }}
    </div>
  </div>

  <div class="brace flex max-lg:flex-col gap-6 md:mb-5 lg:mb-11 font-roboto">
    <card-home class="bg-[#278AC6]"
               :title="t('cardHome.one.title')"
               :body="t('cardHome.one.body')"
               :link="locale == 'fr' ? '/jarrete/inscription' : '/iquitnow/registration'" external
               :button="t('cardHome.one.button')">
      <template v-slot:icon>
        <icon-desktop class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>

    <card-home class="bg-green"
               :title="t('cardHome.two.title')"
               :body="t('cardHome.two.body')"
               :link="localePath('quit-subpage-index-help-phone')"
               :button="t('cardHome.two.button')">
      <template v-slot:icon>
        <icon-phone class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>

    <card-home class="bg-[#0D5EA7]"
               :title="t('cardHome.three.title')"
               :body="t('cardHome.three.body')"
               :link="localePath('quit-subpage-index-help-person')"
               :button="t('cardHome.three.button')">
      <template v-slot:icon>
        <icon-person class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>
  </div>

  <section-facebook />

  <div class="brace max-md:p-0 md:flex md:mt-11 md:mb-4 gap-4 lg:gap-10 font-roboto">
    <div class="flex-1 basis-[42.857%] md:w-0 md:flex">
      <img class="flex-none w-full h-[455px] my-auto object-cover md:rounded-2xl md:w-[600px] md:h-full" :src="width < 860 ? home_meta.image2.mobile.url : home_meta.image2.desktop.url" />
    </div>

    <div class="flex-1 basis-[54.285%] md:w-0 md:flex flex-col px-[32px] py-[74px] md:rounded-2xl md:p-[64px] xl:p-[80px] bg-white">
      <div class="flex-none my-auto">
        <h2 class="mb-8 text-2xl md:text-3.5xl font-bold font-degular">{{ t("preparation.title") }}</h2>
        <p class="mb-3 max-md:text-sm">{{ t("preparation.body1") }}</p>
        <p class="mb-3 max-md:text-sm">{{ t("preparation.body2") }}</p>

        <div class="mt-10 pb-3">
          <nuxt-link class="btn btn--outline" :to="localePath('quit-subpage-index-hesitate-index')">{{ t('preparation.btn') }}</nuxt-link>
        </div>
      </div>
    </div>
  </div>

  <div class="md:mt-[100px] max-md:pt-[42px]">
    <div class="max-md:border-t max-md:border-t-green">
      <div class="brace font-roboto">
        <div class="py-12 md:py-14 md:border-t md:border-t-green">
          <div class="max-w-[900px] mx-auto flex max-md:flex-col gap-14 md:gap-8">
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://cqts.qc.ca/"><img class="inline-block max-md:w-[172px]" width="131" height="80" src="/images/homepage/cqts.svg" /></a>
            </div>
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://www.quebec.ca/"><img class="inline-block" width="189" height="68" src="/images/homepage/quebec.svg" /></a>
            </div>
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://cancer.ca/"><img class="inline-block" width="186" height="62" src="/images/homepage/ccs.svg" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardQuitCtaHome from "~/components/Cards/CardQuitCtaHome.vue";
import CardHome from "~/components/Cards/CardHome.vue"
import HeaderHome from "~/components/Headers/HeaderHome.vue";
import SectionNews from "~/components/Sections/SectionNews.vue";
import SectionPagePreview from "~/components/Sections/SectionPagePreview.vue";
import SectionFacebook from "~/components/Sections/SectionFacebook.vue";
import SectionQuitHeader from "~/components/Sections/SectionQuitHeader.vue";

import ArrowDown from "~/assets/svg/arrow-down.svg";
import IconPerson from "~/assets/svg/icon_person.svg";
import IconDesktop from "~/assets/svg/icon_desktop.svg";
import IconPhone from "~/assets/svg/icon_phone.svg";


import { setupMeta } from "~/utils/utils";
import { useWindowSize } from '@vueuse/core'

export default {
  name: "PageQuitIndex",
  async setup() {
    defineI18nRoute({
      locales: ["fr", "en"],
      paths: {
        fr: "/jarrete",
        en: "/iquitnow",
      },
    });
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const route = useRoute();
    const nuxtApp = useNuxtApp();

    const { information, prevention, quit, latest_posts, home_meta } = nuxtApp.$store.state.web;
    const { width } = useWindowSize()

    // if (!quit.hesitate || !quit.help || !quit.pro) {
    //   await store.dispatch("quit/fetchIndex");
    // }
    await nuxtApp.runWithContext(() => setupMeta(route.query.path, quit.seo));

    return {
      t,
      locale,
      localePath,
      information,
      prevention,
      quit,
      posts: latest_posts,
      home_meta,
      width
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.code === 27 && this.is_drawer_open) this.is_drawer_open = false;
    });
  },
  components: {
    ArrowDown,
    CardHome,
    CardQuitCtaHome,
    HeaderHome,
    IconDesktop,
    IconPerson,
    IconPhone,
    SectionFacebook,
    SectionQuitHeader,
    SectionNews,
    SectionPagePreview,
  },
};
</script>

<style lang="scss">
  html {
    @apply font-roboto;
  }
</style>

<i18n lang="json">
{
  "fr": {
    "hero": {
      "title": "Vous avez envie d'arrêter de fumer ou de vapoter, mais ne savez pas comment faire?",
      "body": "J'arrête est là pour vous aider!"
    },
    "cardHome": {
      "one": {
        "title": "EN LIGNE",
        "body": "Progressez à votre rythme et de façon autonome grâce à nos outils et exercices disponibles 24h/24 . Consultez vos statistiques et suivez vos progrès en temps réel pour vous aider dans votre arrêt.",
        "button": "Je m'inscris"
      },
      "two": {
        "title": "PAR TÉLÉPHONE",
        "body": "Obtenez du soutien et des informations en discutant avec nos spécialistes en cessation. Planifiez des appels de suivi et recevez une aide immédiate entre vos rendez-vous, au besoin.",
        "button": "J'appelle"
      },
      "three": {
        "title": "EN PERSONNE",
        "body": "Dans un de nos centres d'abandon du tabagisme, rencontrez un spécialiste en personne qui vous accompagnera dans votre démarche d'arrêt. Cette même personne vous suivra tout au long de votre processus.",
        "button": "Je prends RDV"
      }
    },
    "preparation": {
      "title": "Je me prépare",
      "body1": "Évaluez votre motivation et votre confiance tout en disant adieu à vos doutes et à vos craintes!",
      "body2": "Explorez des exercices simples conçus pour éliminer l'ambivalence et vous permettre de franchir la prochaine étape : l'arrêt.",
      "btn": "En savoir plus"
    },
    "main": {
      "title": "3 types d'accompagnements personnalisé et gratuit pour arrêter de fumer ou de vapoter",
      "body": "Nos trois types d'accompagnement sont confidentiels et disponibles partout au Québec. Que ce soit en ligne, par téléphone ou en personne, nous offrons un soutien à l’arrêt du tabagisme et du vapotage, sans jugement et selon les meilleures pratiques."
    }
  },
  "en": {
    "hero": {
      "title": "Do you want to quit smoking or vaping but don't know how?",
      "body": "I QUIT NOW is here to help you!"
    },
    "cardHome": {
      "one": {
        "title": "ONLINE",
        "body": "Progress at your own pace and independently with our tools and exercises available 24/7. Check your statistics and track your progress in real time to help you through your quitting journey.",
        "button": "Sign up"
      },
      "two": {
        "title": "BY PHONE",
        "body": "Get the support and information you need by talking to our cessation specialists. Schedule follow-up calls and receive immediate help between your appointments, if needed.",
        "button": "Call now"
      },
      "three": {
        "title": "IN PERSON",
        "body": "At one of our Quit Smoking centers, meet with a specialist in person who will provide ongoing support throughout your quitting journey. You’ll work with the same expert every step of the way, ensuring consistent guidance.",
        "button": "Book an appointment"
      }
    },
    "preparation": {
      "title": "Getting Ready",
      "body1": "Assess your motivation and confidence while bidding farewell to your doubts and fears!",
      "body2": "Explore simple exercises designed to eliminate ambivalence and guiding you to the next step: quitting.",
      "btn": "Learn more"
    },
    "main": {
      "title": "3 types of personalized and free support methods to quit smoking or vaping",
      "body": "Our three types of support are confidential and available throughout Quebec. Whether online, by phone, or in person, we help you quit smoking and vaping, without judgment and according to best practices."
    }
  }
}
</i18n>
